import {ref, Ref} from 'vue'
import {ModalType} from '@/types/ModalType'

export interface ModalMade {
  valueHolder:Ref<ModalType>;
  show:Ref<boolean>;
  showCancel:Ref<boolean>;
  confirm: Ref<()=>void>;
  cancel: Ref<()=>void>;
  alert:(str:string, cb?:()=>any, cb2?:() => any) => any;
  loadOn:(str?:string)=>void;
  loadOff:()=>void;
}

export const ModalMaker = ():ModalMade => {
  const valueHolder =  ref<ModalType>({} as ModalType);
  const show = ref(false);
  const showCancel = ref(false);
  const confirm = ref(() => {console.log('confirm')})
  const cancel = ref(() => {console.log('cancel')})

  const alert = (str:string, cb1?:() => void, cb2?:() => void) => {
    valueHolder.value.title = str;
    show.value = true;
    if(cb1){
      confirm.value = ()=>{
        show.value = false;
        cb1()
      } 
      if(cb2){
        showCancel.value = true
        cancel.value = ()=>{
          show.value = false;
          showCancel.value = false
          cb2()
        } 
      }else{
        cancel.value = () => {show.value = false}
      }
    }else{
      confirm.value = () => {show.value = false}
      cancel.value = () => {show.value = false}
    }
  }
  const loadOn = (str?:string) =>{
    valueHolder.value.title = str || "请稍候...";
    show.value = true;
  }
  const loadOff = () =>{
    show.value = false;
  }

  return {
    valueHolder,
    show,
    showCancel,
    confirm,
    cancel,
    alert,
    loadOn,
    loadOff
  }

} 