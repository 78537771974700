import axios from 'axios'
import { backEndURL } from '@/config'
import store from '@/store'
import { MyAdditions } from '@/types/MyAdditions'

export const updateCoins = (add?:number) => {
    const initialCoins = store.getters.totalCoins
    axios.get( `${backEndURL}/incentive/my-additions?userid=${store.state.userInfo.userid}`)
    .then(res=>{
      console.log('myAdditions', res.data.payload)
      try{
          const {worths, ...myAdditions} = res.data.payload
          store.commit('setWorths', worths as {[key:string]:number})
          store.commit('setMyAdditions', myAdditions as MyAdditions)
          store.commit('doAddCoins',store.getters.totalCoins - initialCoins)
      }catch(e){
        
      }
    }).catch(err=>{
      console.log(err)
    })
}
