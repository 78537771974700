import store from '@/store'
import {OSSDescriptor} from '@/types/OSSDescriptor'

export const getOSSList = (p?:string) => {
  return new Promise<OSSDescriptor[]>((resolve, reject)=>{
    
    const client = store.state.OSSClient

    if(!client) {
      console.log('no oss client')
      reject('no oss client')
      return
    }

    const getList = p?
    ()=>{ 
      return client.listV2(
        {
          prefix:p,
          delimiter:'/'
        }
      )
    } : 
    ()=>{
      return client.list()
    }

    getList()
    .then((l:any)=>{
      resolve(l.objects as OSSDescriptor[] ||[]);
    }).catch((err:Error)=>{
      reject(err)
    })

  })
}
