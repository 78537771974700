import docxtemplater from 'docxtemplater'
import {textFromXML} from './textFromXML' 


export const getPagesPPTX = (doc:docxtemplater):string[][] =>{

  const slideRegex = /^ppt\/slides\/slide[0-9].xml$/

  // recover doc.compiled
  // const keys = Object.keys(doc.compiled).filter(x=>x.match(slideRegex))
  // const pages = keys.map(k=>doc.compiled[k].content).map(xml => textFromXML(xml))
  
  const pages = [[]]

  return pages
}
export const getPagesDOCX = (doc:docxtemplater) =>{

}