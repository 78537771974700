
import Nav from "./Nav.vue"
import { defineComponent, onMounted, ref } from 'vue'
import store from './store'
import { modal } from './modal'
import Modal from "@/components/Modal.vue";
import {version} from './version'
import TreasureHunt from "./components/TreasureHunt/TreasureHunt.vue";
import scroll from '@/scroll'
import axios from "axios";
import { backEndURL } from "@/config";

export default defineComponent({
  name: 'App',
  components: {
    Modal,
    Nav,
    TreasureHunt
  },

  setup(){
    // Modal
    // loading
    store.commit('startFirstEntry')
    store.commit('showLoading')

    // listen to vw changes
    const threshold = 800
    if(store.getters.isMobile){
      window.addEventListener('resize',()=>{
        if(store.state.mobile && document.documentElement.clientWidth>threshold){
          store.commit("setMobile", false)
        }else if((!store.state.mobile) && document.documentElement.clientWidth<=threshold){
          store.commit("setMobile", true)
        }
      })
    }

    axios.get(`${backEndURL}/client-version`)
    .then(res=>{
        if(res.data.status == 200){
            if(res.data.payload !== version){
                const currSplit = version.split('.')
                const targetSplit = res.data.payload.split('.')
                if(
                    (currSplit[0] < targetSplit[0]) ||
                    (currSplit[0] == targetSplit[0]) && (currSplit[1] < targetSplit[1])||
                    (currSplit[0] == targetSplit[0]) && (currSplit[1] == targetSplit[1]) && (currSplit[2] < targetSplit[2])
                ){
                    modal.alert(`华美工作台最新版本是 ${res.data.payload}，请点击左上角按钮刷新以确保正常使用！`)
                }
            }
        }
    }).catch(err=>console.log(err))

    return{ 
      store,
      scroll,
      menuTransitionConfig:"transition: all linear 0.3s;",
      modal, 
      version,

    }
  },
  methods:{
  }
})
