import axios from "axios";
import store from '@/store'
import {initOSS} from "@/utils/";
import {backEndURL} from "@/config";
import { updateCoins } from "@/utils/";
import {jwtStore} from '@/jwtStore';

export const ddLogin2 = (code:string) => {
    return new Promise<number>((resolve, reject) => {
        axios.get(`${backEndURL}/auth/ddlogin?code=${code}`,  {withCredentials: true})
        .then(res=>{
        if(res.data.status === 200){
            const {userInfo, jwt} = res.data.payload;
            document.cookie = `jwt=${jwt};`
            axios.defaults.params = {}
            axios.defaults.params['jwt'] = jwt
            jwtStore.value = jwt
            store.commit("logIn", {userInfo, jwt})
            initOSS()
            updateCoins()
            resolve(1)
        }else{
            if(process.env.NODE_ENV === 'development'){
                console.log('failed to login', res)
            }
            reject('failed to login')
        }
        })
        .catch(err=>{
            if(process.env.NODE_ENV === 'development'){
                console.log('failed to login:', err)
            }
            reject(err)
        })
    })
}