import showTreasure from './showTreasure'

export default () => {
    showTreasure.value = false
    const treasureHunt = document.getElementById('treasureHunt')
    const anchor = document.getElementById('anchor')
    if(!treasureHunt) return
    if(!anchor) return
    setTimeout(()=>{
        const oldTopDistance = window.pageYOffset + anchor.getBoundingClientRect().top - 50
        const newTopDistance = 50 + Math.random() * oldTopDistance
        treasureHunt.style.position = `absolute`
        treasureHunt.style.top = `${newTopDistance}px`
        treasureHunt.style.left = `${ Math.min(Math.random()*100, 90)}%`
        showTreasure.value = true
    },2000)
}