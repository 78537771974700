
import { defineComponent, ref, onMounted, computed, watch } from 'vue'
import ResizeObserver from 'resize-observer-polyfill';

export default defineComponent({
  props: {
    expand: {
      default: true
    },
    manner: {
      default: 'vertical fade'
    },
    transition: {
      default: 'all 0.3s ease-in-out'
    },
    playIn:{
      default:false,
    }
  },
  setup(props) {

    const childWidth = ref(0)
    const childHeight = ref(0)
    const initialized = ref(false)
    const thiselement = ref(document.createElement('div')) 

    onMounted(() => {
      
      const elem = thiselement.value.querySelector("#inner") as Element;
      const child = elem.children[0] as HTMLElement;
      
      if(!child){return}

      child.style.whiteSpace = 'nowrap';

      const ro = new ResizeObserver(children => {
        for (let child of children) {
          const rect = child.contentRect;
          childHeight.value = rect.height + rect.top
          childWidth.value = rect.width + rect.left
        }
      });

      ro.observe(child)

      if(props.playIn){
        initialized.value = true
      }
    })

    watch(()=>props.expand,()=>{
      initialized.value = true
    })

    const width = computed(()=>{
      if(props.manner.includes('horizontal')){
        if(!props.expand){
          return 0 + 'px'
        }else{
          return childWidth.value + 'px'
        }
      }else{
        return '100%'
      }
    })

    const height = computed(()=>{
      if(props.manner.includes('vertical')){
        if(!props.expand){
          return 0 + 'px'
        }else{
          return childHeight.value + 'px'
        }
      }else{
        return '100%'
      }
    })

    const opacity = computed(()=>{
      if(
        (!props.expand)
        && props.manner.includes('fade')
      ){
        return 0
      }else{
        return 1
      }
    })
    
    return {
      thiselement,
      width,
      height,
      opacity,
      initialized
    }
  }
})
