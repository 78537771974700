import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "_wrapper",
  style: {"width":"100%","overflow":"hidden"},
  ref: "thiselement"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "expander",
      style: _normalizeStyle({
      overflow: 'hidden',
      transition:_ctx.initialized?_ctx.transition:'',
      width: _ctx.width, 
      height: _ctx.height,
      opacity: _ctx.opacity,
    })
    }, [
      _createElementVNode("div", {
        style: _normalizeStyle({
        width:_ctx.manner.includes('vertical')? '100%' : 'fit-content',
        height:_ctx.manner.includes('horizontal')? '100%' : 'fit-content',
      }),
        id: "inner"
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 4)
    ], 4)
  ], 512))
}