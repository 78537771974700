export const getCookie = (name: string) => {
  const allCookies = document.cookie || '';
  if(allCookies.length < 0) {
    return ''
  }
  
  const cookieStrings = allCookies
              .split(';')
              .map((s:string)=>s.trim())
  if(!cookieStrings) {return ''}

  const targetString = cookieStrings.find((s:string)=>s.startsWith(name))
  if(!targetString) {return ''}

  const res = targetString.split('=')[1]

  return res || ''
}