export const binaryRead = (f:File) =>{
  return new Promise<string>((resolve, reject) =>{
    const r = new FileReader();
    r.readAsBinaryString(f)
    r.onerror = function (evt) {
      alert("error reading file" + evt)
      reject(evt)
    }
    r.onload = function (evt) {
      if(evt && evt.target){
        resolve(evt.target.result as string)
      }else{
        reject(evt)
      }
    }
  })
}