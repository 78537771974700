import axios from 'axios';
import vuex from '@/store'
import { backEndURL } from "@/config";

interface OSSConfig {
    bucket: string,
    region: string,
    accessKeyId: string,
    accessKeySecret: string,
    stsToken: string
}

export const getOSSKey = () => {
  return new Promise<OSSConfig>((resolve, reject)=>{
    axios.get(`${backEndURL}/projects/oss-key?jwt=${vuex.state.jwt}`)
    .then(res=>{
      if(res.data.status == 200){
        resolve(res.data.payload)
      }else{
        reject()
      }
    })
    .catch(err=>{
      console.log("getOSSKey Err", err)
    })
  })
}