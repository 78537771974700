<template>
    <transition name="fade">
      <div class="_card" v-if="show" @click.stop.prevent="emit('close')">
        <div class="_card_body" @click.stop.prevent="">
          
          <div class="_card_title">
            {{model.title}}
          </div>

          <div class="_card_content" v-if="model.content && model.content.length>0">
              {{model.content}}
            <slot/>
          </div>

          <div class="buttons">
            <div class="button"  v-if="model.showConfirm || true" 
            @click.stop.prevent="emit('confirm')">
              确定
            </div>
            <div class="button" v-if="showCancel" 
              @click.stop.prevent="emit('cancel')">
              取消
            </div>
          </div>

        </div>
      </div>
    </transition>
</template>

<script>
export default {
  props: {
    show: {
      default: false,
    },
    showCancel: {
      default: false
    },
    model: {
      default:{
        title:"标题",
        content:"",
        showConfirm:true,
      }
    }
  },
  setup(props, {emit}){
    return {
      emit
    }
  }
}
</script>

<style scoped>
._card{
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color:white;
  font-weight: 250;
}
._card_body{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* min-height: 32%; */
  width: 60%;
  max-width:400px;
  min-width: 300px;
  background-color: rgba(40, 40, 40, 0.81);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-evenly;
}
._card_title{
  font-size: 18px;
  padding: 30px 10px;
  position: relative;
}
._card_title>a{
  color: white;
}
._card_title>a:hover{
  color: rgb(208, 208, 208);
}
._card_subtitle{
  position: relative;
  /* top:100%; */
  white-space: nowrap;
  font-size: var(--font-size-7);
}

._card_content{
  /* width: 100%; */
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 17px;
  padding: 20px 10px;
  text-align: justify;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.buttons{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  user-select:none;
  padding-bottom: 20px;
}
.button{
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: rgba(60, 60, 60, 0.92);
}
.button:hover{
  background-color: rgba(40, 40, 40, 0.92);
}
</style>