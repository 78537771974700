const axios = require('axios')
import {postToOSS} from '@/utils/'
// import CKEditor from 'ckeditor.js';

export const imgUploadPlugin =  (editor:any) => {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader:any) =>
    new UploadAdapter(loader)
}

// Custom Upload Adapter
class UploadAdapter {
  constructor(loader:any) {
    this.loader = loader
  }

  loader:{file:Promise<any>};

  upload(){
    return new Promise((resolve, reject) => {
      this.loader.file.then((file) => {
        console.log(`Uploading`)

        postToOSS(file,'staff/wenda')
        .then((url) => {

          console.log('wenda res', url)

          resolve({urls:{
            default:url
          }})
        })
        .catch((err) => reject(err))
      })
    })
  }

  abort() {
    return Promise.reject()
  }
}