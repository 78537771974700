import store from './store'

export default (modal:any) => {
    if( process.env.NODE_ENV === 'production'){
        // @ts-ignore
        console.error = (...args:any) => {
            try{
                if(store.state.userInfo.name != '谭天') return 
                const str = args.map(x=>JSON.stringify(x)).join('\n')
                modal.alert(str)
                // alert(str)
            }catch(err){
                console.log(err)
            }
        }
    }
}