import axios from 'axios';
import store from '@/store'
import { backEndURL } from "@/config";
import { DDFile } from '@/types/DDFile';


export const getDDFiles2 = (spaceId?:string,parentId?:string) => {
  const jwt = store.state.jwt
  const unionid = store.state.userInfo.unionid
  console.log('unionid:', unionid, spaceId, parentId)
  return new Promise<DDFile[]>((resolve, reject) => {
    axios.get(`${backEndURL}/ddApi/v2/ding-files?spaceId=${spaceId||''}&parentId=${parentId || ''}&unionid=${unionid}`)
    .then(res=>{
      if(res.data.status == 200){
        const resolution = res.data.payload
        resolve(resolution);
      }else{
        reject(res)
      }
    }).catch(err => {
        console.log('getDDFiles ERR', err)
        reject(err)
    });
  })
}
