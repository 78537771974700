import { corpId } from '@/config';
import * as dd from 'dingtalk-jsapi'; 

export const uploadDDFile = (spaceId:string) => {
    return new Promise((resolve, reject) => {
        dd.ready(() => {
            dd.biz.util.uploadAttachment({
                image:{multiple:true,compress:false,max:9,spaceId},
                space:{corpId,spaceId,isCopy:1 , max:9},
                file:{spaceId,max:1},
                types:["photo","file","space"],//PC端支持["photo","file","space"]
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err)
            })
        })
    })
}