import axios from 'axios';
import store from '@/store'
import { backEndURL } from "@/config";
import { DDFile } from '@/types/DDFile';

interface DDRes {
  files: DDFile[],
  nextToken: string,
  requestId: string
}


export const getDDFiles = (reload?:boolean) => {
  const jwt = store.state.jwt
  const unionid = store.state.userInfo.unionid
  const _reload = reload || false
  return new Promise<DDFile>((resolve, reject) => {
    // if (store.state.DDFileCache && !_reload) {
    //   console.log('getDDFiles cache')
    //   resolve(store.state.DDFileCache)
    //   return
    // }
    axios.get(`${backEndURL}/ddApi/ding-files?reload=${_reload}&unionid=${unionid}`)
    .then(res=>{
      if(res.data.status == 200){
        const resolution = res.data.payload
        //   createTime: "",
        //   creator: "",
        //   fileId: "",
        //   fileName: "",
        //   filePath: "",
        //   fileSize: 0,
        //   fileType: "folder",
        //   modifier: "",
        //   modifyTime: "",
        //   parentId: "",
        //   spaceId: "",
        // }
        console.log('getDDFiles', resolution)
        store.commit("setDDFileCache", resolution)
        resolve(resolution);
      }else{
        reject(res)
      }
    }).catch(err => {
        console.log('getDDFiles ERR', err)
        reject(err)
    });
  })
}
