import axios from 'axios';
import vuex from '@/store'
import { backEndURL } from "@/config";
import {TeamMember} from '@/types/TeamMember';

export const getTMs = () => {
  return new Promise<TeamMember[]>((resolve, reject) => {
    axios.get(`${backEndURL}/ddApi/get-contacts?jwt=${vuex.state.jwt}`)
    .then(res=>{
      resolve(res.data.payload);
    }).catch(err => reject(err));
  })
}
