
import store from '@/store'
import {ref, defineComponent, watch, onMounted} from 'vue'
import axios from 'axios'
import { backEndURL } from "@/config";
import showTreasure from './showTreasure'

export default defineComponent({
  props: {
      show: {
          default:false
      }
  },
  setup(props, {emit}){

        const treasureWorth = 1

        const coinImg = ref<HTMLElement>()


        const collect = () => {
            axios.post(
            `${backEndURL}/treasure-hunt/collect?userid=${store.state.userInfo.userid}`
            ).then(res=>{
                store.commit('doAddCoins', treasureWorth)
            }).catch(err=>{
                store.commit('doAddCoins', treasureWorth)
            })
            showTreasure.value = true
        }

        const coinWidth = (0.6 + 0.4 * Math.random()) * 30
        const coinOpacity = ref(0)
        const translateX = ref(0)
        const translateY = ref(0)
        onMounted(()=>{
            let reverse = false
            
            setInterval(() => {
                if(coinOpacity.value > 0.5 ){
                    reverse = true
                }else if(coinOpacity.value < -0.3){
                    reverse = false
                }
                coinOpacity.value += reverse ? -0.05 : 0.05
            },50)

            // setInterval(()=>{
            //     // const maxTranslate = 500
            //     const Xrand = Math.random() > 0.5 ? 1 : -1
            //     const Yrand = Math.random() > 0.5 ? 1 : -1
            //     translateX.value += Xrand * 1
            //     translateY.value += Yrand * 1
            // },100)
        })


        // add coins
        const coin_enter = ref(false)
        watch(()=>store.state.addCoins, ()=>{
            if(store.state.addCoins==0){
                return
            }
            coin_enter.value=true
            setTimeout(() => {
                coin_enter.value=false
                store.commit('resetAddCoins')
            }, 1000);
        })


        return {
            coinImg,
            coinWidth,
            coinOpacity,
            showTreasure,
            collect,
            store,
            coin_enter, 

            translateX,
            translateY,
        }
    }
})
