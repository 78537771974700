const userAgent = navigator.userAgent.toLowerCase()
const isDD = userAgent.includes('dingtalk')
import { webLogin, validateJWT, ddLogin, ddLogin2 } from './utils/'
import store from './store'
import {getAuths} from '@/utils/auth/getAuths'
import {authStore} from '@/utils/auth/authStore'
import {updateCoins, getTMs} from '@/utils/'
import axios from 'axios'
import { initOSS } from '@/utils/'

export const handleLogin = () => {
    
    axios['_tt_login'] = true

    return new Promise<void>((resolve, reject) => {

        // TMs
        getTMs()
        .then(tms =>{
          store.commit('setTeamMembers', tms.sort((a,b) => a.hired_date - b.hired_date))
        }).catch((err:Error)=>{
          console.log('failed to get tms', err)
        })
        
        if(process.env.NODE_ENV == 'production'){

          if(isDD){
            ddLogin()
            .then(info=>{
              ddLogin2(info.code)
              .then(()=>{
                    if(!store.getters.isFulltime){
                        store.commit('hideLoading')
                    }
                    getAuths().then(res=>{
                        authStore.value=res
                        resolve()
                    }).catch(err=>{
                        alert('获取权限信息失败，请刷新页面 (01)')
                        window.location.reload()
                        reject()
                    })
                    // get incentive beans
                    updateCoins()
              }).catch(err=>{
                    alert('登录失败，请刷新页面（01）')
              })
            })
            .catch(err=>{
                reject()
                alert('登录失败，请刷新页面（02）' + err)
                 console.log('dd login error: ' + err)
            })
          }else{
            const urlSearchParams = new URLSearchParams(window.location.search)
            const { code } = Object.fromEntries(urlSearchParams.entries());
        
            if( code ){
              webLogin(code)
              .then(()=>{
                if(!store.getters.isFulltime){
                  store.commit('hideLoading')
                  resolve()
                }
                updateCoins()
              }).catch(err=>{
                alert('登录失败，请刷新页面（03）')
              })
            }else{
              validateJWT()
              .then(()=>{
                if(!store.getters.isFulltime){
                  store.commit('hideLoading')
                  resolve()
                }
                // get incentive beans
                updateCoins()
              }).catch(err=>{
                alert('登录失败，请刷新页面（04）'+ err)
              })
            }
          }
        }else{
            // local 
            const jwt = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY3RpdmUiOnRydWUsImFkbWluIjp0cnVlLCJhdmF0YXIiOiJodHRwczovL3N0YXRpYy1sZWdhY3kuZGluZ3RhbGsuY29tL21lZGlhL2xBRFBCRnVOYzVMQ2xTUE5BdTdOQXU0Xzc1MF83NTAuanBnIiwiYm9zcyI6ZmFsc2UsImRlcHRfaWRfbGlzdCI6WzExNzUxMTc0Nl0sImRlcHRfb3JkZXJfbGlzdCI6W3siZGVwdF9pZCI6MTE3NTExNzQ2LCJvcmRlciI6MTc3OTE3NjIxNzc5NDYwNTAwfV0sImV4Y2x1c2l2ZV9hY2NvdW50IjpmYWxzZSwiaGlkZV9tb2JpbGUiOmZhbHNlLCJoaXJlZF9kYXRlIjoxNjA5MDg0ODAwMDAwLCJqb2JfbnVtYmVyIjoiIiwibGVhZGVyX2luX2RlcHQiOlt7ImRlcHRfaWQiOjExNzUxMTc0NiwibGVhZGVyIjpmYWxzZX1dLCJuYW1lIjoi6LCt5aSpIiwicmVhbF9hdXRoZWQiOnRydWUsInJvbGVfbGlzdCI6W3siZ3JvdXBfbmFtZSI6IuWRmOW3peexu-WeiyIsImlkIjoyNDU1MzQ5MDM0LCJuYW1lIjoi5YWo6IGM5ZGY5bel77yI5LiN5ZCr6K-V55So5pyf5ZGY5bel77yJIn0seyJncm91cF9uYW1lIjoi6buY6K6kIiwiaWQiOjY1NDkyMjIyLCJuYW1lIjoi5a2Q566h55CG5ZGYIn0seyJncm91cF9uYW1lIjoi5bKX5L2NIiwiaWQiOjY1NDkyMjMyLCJuYW1lIjoi6aqo5bmyIn1dLCJzZW5pb3IiOmZhbHNlLCJ0aXRsZSI6IuaKgOacr-WPkeWxleWJr-aAu-ebkSIsInVuaW9uX2VtcF9leHQiOnt9LCJ1bmlvbmlkIjoiRllxTGtpU0NlZzNTb1dtaVNSTmtFTWhnaUVpRSIsInVzZXJpZCI6IjE2MDkxMTkwMjk5Nzk0Nzc2IiwiaWF0IjoxNjgyMDQzNzY0LCJleHAiOjE2ODIxMzAxNjR9.3FZo0jk3Z4U1NVDySR8SEqN3zp5GV8GsJuQP-ucA5vs"
            document.cookie = `jwt=${jwt};`
            axios.defaults.params = {}
            axios.defaults.params['jwt'] = jwt
            store.commit("logIn",
            { 
                userInfo: {
                            dept_id_list:[117511746],
                            userid:'16091190299794776',
                            name:"谭天",
                            role_list:[
                                {
                                    group_name: '员工类型',
                                    name: '全职员工（不含试用期员工）'
                                }
                            ],
                            title:"技术发展副总监",
                            unionid:'FYqLkiSCeg3SoWmiSRNkEMhgiEiE'
                        }, 
                jwt:jwt
            })
            if(!store.getters.isFulltime){
                store.commit('hideLoading')
            }
            // get incentive beans
            updateCoins()
    
            getAuths().then(res=>{
                authStore.value=res
                resolve()
            }).catch(err=>{
                alert('获取权限信息失败，请刷新页面（02）')
                // window.location.reload()
                resolve()
            })
            initOSS()
            store.commit("resetWendaFilterTab")
            store.commit("resetWendaFilterStage")
            store.commit("resetWendaFilterSearch")
        }
    })

}