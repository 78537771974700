<template>
  <button class="edit-button">
    {{text}}
    <!-- <slot/> -->
  </button>
</template>

<script>

export default {
  name:"TButton",
  props: {
    text: {
      type: String,
      required: false
    },
  },
}

</script>

<style scoped>

.edit-button{
  border:none;
  border-radius:5px;
  min-width: 55px;
  min-height: 30px; 
  padding-left:10px;
  padding-right:10px;
  margin-bottom:5px;
  color:rgba(99, 99, 99);
  transition: all linear 0.1s;
  height: 32px;
  min-width: 60px;
  font-size: 15px;
  background-color: rgba(210, 208, 208, 0.564);
  backdrop-filter: blur(6px);
}

.edit-button:hover{
  background-color: rgb(210, 208, 208);
  color:rgb(91, 91, 91);
  cursor: pointer;
}

</style>