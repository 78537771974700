import axios from 'axios'
import { backEndURL } from '@/config'

export const recordVisit = (message:any) => {
    const timestamp = Date.now()
    axios.post( `${backEndURL}/record-visit`,
        {   
            site:'dd',
            timestamp,
            message: JSON.stringify(message)
        }
    )
    .then(res=>{ 
        console.log(res)
    }).catch(err => {
        console.log(err)
    })
}
