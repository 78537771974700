export const waitOn = (container: {[key: string]:any}, key: string, m?: number) => {
  console.log("waiting on")
  const maxCount = m || 1000
  let counter = 0 
  return new Promise<any>((resolve, reject) =>{
    const wait = setInterval(() =>{
      counter ++
      if(container[key] != undefined){
        resolve(container[key]);
        clearInterval(wait);
      }
      if(counter >= maxCount){
        clearInterval(wait);
        reject('wait timeout')
      }
    },500)
  })
}