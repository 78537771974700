import axios from 'axios'
const URL = 'https://staff.huamei2001.com'

export const getGeo = () => {
    return new Promise<any>((resolve, reject)=>{
        axios.get(`${URL}/geo.json`).then(res=>{
            console.log('GET GEO RES', res)
            if(Array.isArray(res.data)){
                resolve(res.data)
            }else{
                resolve([])
            }
        }).catch(err=>{
        })
    })
}