import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import {TeamMember} from '@/types/TeamMember';
import {UserInfo} from '@/types/UserInfo';
import { MyAdditions } from '@/types/MyAdditions';
import { CatalogStruct } from '@/views/wenda/types';
import { BookType } from 'xlsx';
import { CatalogRecord } from '@/views/wenda/types';
import { DataVisReportOption } from '@/views/data_vis/types'

import {getGeo} from '@/utils/getGeo'
import coinsAdded from './coinsAdded';

export interface VuexState {
  geo:any,

  DDFileCache:any,

  firstEntry:boolean;
  loading:boolean;

  myAdditions: MyAdditions;
  worths:{[key:string]:number};

  internal: boolean;

  mobile:boolean;

  OSSClient:any | undefined,

  // coins
  addCoins:number;


  // auth
  loginState:boolean,
  jwt:string,

  userInfo:UserInfo,

  menuAssort:any[],

  // ui
  menuShow:boolean,

  //routing
  activeTab:string,
  
  // projects
  projectFilters:string[],
  
  // questionFilters
  wendaLines:CatalogRecord[],
  wendaCatalog:CatalogStruct,
  wendaFilterTab:{label:string, id:number, children?:any[]},
  wendaFilterStage:{label:string, id:number},
  wendaFilterSearch:string,

  teamMembers:TeamMember[]

  // data vis
  dataVisReportLists: DataVisReportOption[],
  dataVisCurrTab:any, // tabOption 
  dataVisCurrCategory:string,
  dataVisCurrType:string,
  dataVisCurrInstance:string,
  lastViewed:{category:string, type:string, instance:string, source:string, title:string, urls:string[]},
}

const store = createStore({
  plugins: [createPersistedState()], 
  state: {
    geo:[],
    DDFileCache:undefined,
    firstEntry:true,
    loading:false,
    worths:{
      answers:0,
      links:0,
      questions:0,
      wenda:0,
      projects:0,
      orders:0
    },

    myAdditions:{
      answers:[],
      links:[],
      questions:[],
      wenda:[],
      projects:[],
      contacts:[],
      orders:[]
    },

    addCoins:0,

    internal: true,

    mobile:false,

    OSSClient: undefined as any | undefined,

    // auth
    loginState:false,
    jwt:"",

    userInfo:{
      avatar:'/images/user.png',
      name:'',
      dept_id_list:[0],
      userid: '-1',
    } as UserInfo,

    menuAssort:[],

    // ui
    menuShow:false,
    //routing
    activeTab:"",
    
    // projects
    projectFilters:[],
    
    // questions
    wendaLines:[],
    wendaCatalog:{} as CatalogStruct,
    wendaFilterTab:{label:'定位可研', id:119, children:[]},
    wendaFilterStage:{label:'', id:-1},
    wendaFilterSearch:'',
    teamMembers:[] as TeamMember[],

    // data-vis
    dataVisReportLists: [],
    dataVisCurrTab:'', 
    dataVisCurrCategory:'',
    dataVisCurrType:'',
    dataVisCurrInstance:'',
    lastViewed:{
        category:'custom',
        source:'custom',
        type: 'city',
        instance: '哪酒店最多',
        title: '哪酒店最多',
        urls:[]
    },
  } as VuexState,

  mutations: {
    setGeo(state:VuexState, geo:any){
      state.geo = geo
    },
    setDDFileCache(state:VuexState, DDFileCache:any){
      state.DDFileCache = DDFileCache
    },
    startFirstEntry(state:VuexState){
      state.firstEntry = true
    },
    doneFirstEntry(state:VuexState){
      state.firstEntry = false
    },
    showLoading(state:VuexState){
      state.loading = true
    },
    hideLoading(state:VuexState){
      state.loading = false
    },
    setWendaCatalog(state:VuexState, newData:CatalogStruct){
      state.wendaCatalog = newData
    },

    setMyAdditions(state: VuexState, myAdditions:MyAdditions){
      state.myAdditions = myAdditions
    },
    setWorths(state: VuexState, worths:{[key:string]:number}){
      state.worths = worths
    },

    internalOn(state: VuexState){
      state.internal =  true;
    },
    internalOff(state: VuexState){
      state.internal =  false;
    },

    toggleInternal(state:VuexState){
      state.internal = !state.internal
    },
    initOSS(state:VuexState, client:any){
      state.OSSClient = client
    },

    toggleMenu(state){
      state.menuShow = !state.menuShow;
    },
    
    logIn(state:VuexState, data:{userInfo:UserInfo, jwt:string}){
      state.loginState = true;
      state.userInfo = data.userInfo;
      state.jwt = data.jwt
    },

    logOut(state){
      state.loginState = false;
      state.userInfo = {} as UserInfo
    },

    switchTab(state, to){
      state.activeTab = to
    },

    // projects
    setProjectFilters(state, filters){
      state.projectFilters = filters;
    },
    // questions
    setWendaLines: (state, lines:CatalogRecord[]) =>state.wendaLines = lines,
    setWendaFilterTab: (state, value:{label:string, id:number}) => {state.wendaFilterTab = value;state.wendaFilterStage = {label:'', id:-1}},
    setWendaFilterStage: (state, value:{label:string, id:number}) => state.wendaFilterStage = value,
    setWendaFilterSearch: (state, value:string) => state.wendaFilterSearch = value,
    resetWendaFilterTab: (state) => state.wendaFilterTab = {label:'定位可研', id:119},
    resetWendaFilterStage: (state) => state.wendaFilterStage = {label:'', id:-1},
    resetWendaFilterSearch: (state) => state.wendaFilterSearch = '',
    setTeamMembers(state, tm:TeamMember[]){
      state.teamMembers = tm
    },

    // coins 
    doAddCoins:(state, number) => { 
        state.addCoins = number 
        coinsAdded.value += number
    },
    resetAddCoins:(state) => state.addCoins = 0 ,

    // data vis
    setDataVisReportLists:(state, newLists: DataVisReportOption[])=>{
      state.dataVisReportLists = newLists
    },
    setLastViewed: (state, item:{category:string,type:string, instance: string, source:string, title:string, urls:[]}) => state.lastViewed = item,
    setDataVisCurrTab: (state, str:string) => state.dataVisCurrTab = str,
    setDataVisCurrCategory: (state, str:string) => state.dataVisCurrCategory = str,
    setDataVisCurrType: (state, str:string) => state.dataVisCurrType = str,
    setDataVisCurrInstance: (state, str:string) => state.dataVisCurrInstance = str
  },
  
  getters:{
    isMobile: state => {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      if (/Mobi/.test(navigator.userAgent) || vw < 700) {
        return true
      }else{
        return false
      }
    },
    headerHeight:  state => {
      return "50px" ;
    },
    totalCoins: state => {
      interface Worth {worth:number}
      let total = 0
      Object.values(state.myAdditions).forEach(arr=>{
        total += arr.map((v:Worth)=>v.worth).reduce((a:number,b:number)=>a+b,0)
      })
      return total
    },
    wendaPlaceholder : state => {
      if(state.wendaFilterTab.label && state.wendaFilterStage.label){
        return `在 ${state.wendaFilterTab?state.wendaFilterTab.label:''} - ${state.wendaFilterStage?state.wendaFilterStage.label:''} 中搜索`
      }else if(state.wendaFilterTab.label){
        return `在 ${state.wendaFilterTab?state.wendaFilterTab.label:''} 中搜索`
      }else{
        return `在 流程&问答 中搜索`
      }
    },
    getWendaTab: state => {
      return state.wendaFilterTab.label || '定位可研'
    },
    isAdmin:state=>{
        return ['谭天','王炜文'].includes(state.userInfo.name)
    },
    isFulltime: state => {

        // white list
        if(['杨明亮','王炜文','刘依敏','洪光宇',"陈思语",'陈浩'].includes(store.state.userInfo.name)){return true}
        // black list
        if(['孙沁园'].includes(store.state.userInfo.name)){return false}

        if(!state.userInfo){return false}
        if(!state.userInfo.role_list){return false}
        if(!state.userInfo.role_list.length){return false}
        if(state.userInfo.role_list.length == 0){return false}

        if(
            state.userInfo.role_list.length > 0 && 
            state.userInfo.role_list
            .filter(x=>{
                return x.group_name == '员工类型' && x.name == '全职员工（不含试用期员工）'
            }).length>0
        ){
            return true
        }
        return false
        
    }
  },
})

store.commit('initOSS',undefined)

export default store

store.commit('setProjectFilters',[])
store.commit('resetWendaFilterTab')
store.commit('resetWendaFilterStage')
store.commit('resetWendaFilterSearch')
store.commit('resetAddCoins')
store.commit('setLastViewed', {
    category:'custom',
    source:'custom',
    type: 'city',
    instance: '哪酒店最多',
    title: '哪酒店最多',
    urls:[]
})

getGeo().then(geo=>{
  store.commit('setGeo', geo)
})


console.log('wenda tab', store.state.wendaFilterTab)