import OSS from "ali-oss"
import { getOSSKey } from '.';

export const postToOSS = (file:File, p:string) => {
  const path = p||'staff/misc'
  console.log("file", file) 

  return new Promise<string|string[]>((resolve, reject)=>{
    getOSSKey()
    .then(ossConfig=>{
      const client = new OSS(ossConfig);
      try{ 
        const ossPath = `${path}/public_${file.name}`;
        client.put(ossPath, file)
        .then(()=>{
          const url = `https://oss.huamei2001.com/${ossPath}`
          resolve(url);
        })
        .catch((err:Error)=>{
          console.log( 'failed saving to oss', err)
        })
      }catch(err){
        console.log("upload",err)
        alert(`${file.name}上传失败，请稍后再试`);
        reject();
      }
    })
    .catch(err=>{
      console.log(err)
      reject();
    })
  })
}

