import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {} from './utils/globalMethods'
import {backEndURL} from './config'
import {getHotelData} from './store/hotelData'

import { 
  TreeSelect, 
  // DatePicker, 
  Input, 
  Select, 
  Tooltip, 
  Upload, 
  Modal, 
  Button, 
  Cascader, 
  Checkbox, 
  Radio,
  Tabs, Dropdown, Menu
} from 'ant-design-vue';

import axios from 'axios'
import {handleLogin} from './handleLogin'


import plugin from '@tian-libs/menuleft'
import mapNext from '@tian-hm/map-next'
import designExamples from '@tian-hm/design-examples'
import hmPicks from '@tian-hm/hmpicks'
import hmGallery from '@tian-hm/hmgallery'
import reportviewer from '@tian-hm/reportviewer'
import dpcViewer from '@tian-hm/dpc-viewer'


// axios.defaults.withCredentials = true

getHotelData()

const vue = createApp(App)

vue.config.warnHandler = () => null;

handleLogin().then(() => {
    vue.use(store)
    .use(plugin)
    .use(mapNext)
    .use(designExamples)
    .use(hmPicks)
    .use(hmGallery)
    .use(dpcViewer)
    .use(reportviewer)
    .use(router)
    // .use(DatePicker)
    .use(Input)
    .use(Select)
    .use(TreeSelect)
    .use(Tooltip)
    .use(Upload)
    .use(Modal)
    .use(Button)
    .use(Cascader)
    .use(Checkbox)
    .use(Radio)
    .use(Tabs)
    .use(Dropdown)
    .use(Menu)
    .mount('#app')
})

Array.prototype.unique = function(){
  return this.reduce((a,b)=>a.includes(b)?a:[...a,b],[]);
}

// set coin factor
import coinFactor from './coinFactor'
axios.get(`${backEndURL}/coin-factor`)
.then(res=>{
    if(res.data.status == 200){
        coinFactor.value = res.data.payload
    }
})