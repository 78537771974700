import axios from 'axios';
import vuex from '@/store'
import { backEndURL } from "@/config";

export const getDDTicket = () => {
  return new Promise<any>((resolve, reject) => {
    const currURL =encodeURIComponent(window.location.href)
    axios.get(`${backEndURL}/ddApi/js-ticket?currURL=${currURL}`)
    .then(res=>{
      resolve(res.data.payload);
    }).catch(err => reject(err));
  })
}
