import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea3b67de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "treasureHunt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.showTreasure && false)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: "/images/coin.png",
              ref: "coinImg",
              class: "treasure_hunt_img",
              style: _normalizeStyle(`opacity:${_ctx.coinOpacity}; 
                    width:${_ctx.coinWidth}px; 
                    height:${_ctx.coinWidth}px;
                    transform:translate(${_ctx.translateX}px, ${_ctx.translateY}px)`),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.collect && _ctx.collect(...args))),
              alt: ""
            }, null, 4))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}