export const testURL = (str: string) =>{
  // const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  //   '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  //   '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  //   '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

  const regexString = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i
  const pattern = regexString;
  return !!pattern.test(str);
}