import * as dd from 'dingtalk-jsapi'
import { DDFile } from '@/types/DDFile'
import { ModalMade } from '@/components/ModalMaker'

export const viewFile = (file:DDFile | undefined, modal?:ModalMade) => {
  if(!file){return}
  const {spaceId, fileId, fileName, fileSize, fileType} = file
  const config = {
      corpId: 'ding9c9a7a645dc88ccb35c2f4657eb6378f',
      spaceId, fileId, fileName, fileSize, fileType,
  }
  dd.ready(()=>{
    dd.biz.cspace.preview(config)
    .then()
    .catch(err=>{
      if(modal){
        modal.alert('预览失败，请刷新页面！')
      }
      console.log(err)
    })
  })
}