import { createRouter, createWebHistory } from 'vue-router'
import Empty from '../views/Empty.vue'

const routes = [
  {
    path:"/unauthorized",
    component:Empty,
  },
  {
    path:"/",
    redirect:'/news',
    meta:{requiresAuth:true}
  },
  {
    path:"/manage-accounts",
    meta:{requiresAuth:true},
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "manage-accounts" */'../views/manageAccounts/Manage.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:"/manage-dpc",
    meta:{requiresAuth:true},
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "manageDPC" */'../views/manageDPC/Manage.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:"/news",
    meta:{requiresAuth:true},
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "news" */'../views/news/News.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/news/add/:id',
    meta:{requiresAuth:true},
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "news-add" */'../views/news/AddNews.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:"/mycoins",
    meta:{requiresAuth:true},
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "mycoins" */'../views/incentive/MyCoins.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
//   {
//     path:"/hmscrape",
//     meta:{requiresAuth:true},
//     component:Empty,
//     children:[
//       { 
//         path:'',
//         component: () => import('../views/hmScrape/HMScrape.vue'),
//         meta:{requiresAuth:true}
//       },
//     ]
//   },
  {
    path:"/dpc/mapWhere",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "mapWhere" */'../views/data_vis/MapWhere.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:"/postdupublic",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "postdupublic" */'../views/publicPost/PublicPost.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/projects',
    component:Empty,
    name:"项目故事",
    children:[
      { 
        name:"project",
        path:'project/:id',
        component: () => import(/* webpackChunkName: "projects" */ '../views/projects/EditProject.vue'),
        meta:{requiresAuth:true}
      },
      { 
        path:'view-projects/:page',
        component: () => import(/* webpackChunkName: "view-projects-page" */ '../views/projects/ViewProjects.vue'),
        meta:{requiresAuth:true}
      },
      { 
        path:'view-projects',
        component: () => import(/* webpackChunkName: "view-projects" */ '../views/projects/ViewProjects.vue'),
        meta:{requiresAuth:true}
      },
      { 
        path:'project-proposals',
        component: () => import(/* webpackChunkName: "project-proposals" */ '../views/projects/ViewProjects.vue'),
        meta:{requiresAuth:true}
      },
      { 
        name:"edit-project",
        path:'edit-project/:id',
        component: () => import(/* webpackChunkName: "edit-project" */ '../views/projects/EditProject.vue'),
        meta:{requiresAuth:true}
      },
      { 
        path:'create-project',
        component: () => import(/* webpackChunkName: "create-project" */ '../views/projects/EditProject.vue'),
        meta:{requiresAuth:true}
      }
    ]
  },
  {
    path:'/huamei',
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "huamei" */ '../views/huamei/Huamei.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/procedures',
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "procedures" */ '../views/procedures/Procedures.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/wenda',
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "wenda" */ '../views/wenda/Wenda.vue'),
        meta:{requiresAuth:true}
      },
      { 
        path:'new-question',
        component: () => import(/* webpackChunkName: "new-question" */ '../views/wenda/NewQuestion.vue'),
        meta:{requiresAuth:true}
      },
      { 
        path:'edit-question/:id',
        component: () => import(/* webpackChunkName: "edit-question-id" */ '../views/wenda/NewQuestion.vue'),
        meta:{requiresAuth:true}
      },
      { 
        path:'view-question/:id',
        component: () => import(/* webpackChunkName: "view-question-id" */ '../views/wenda/ViewQuestion.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/relic/:relicType/:id',
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "relic-relicType-id" */ '../views/relicPage/RelicPage.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/templator',
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "templator" */ '../views/templator/Templator.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/data-vis',
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "data-vis" */ '../views/data_vis/DataVis.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/useful-links',
    name:"知识地图",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "useful-links" */ '../views/useful_links/UsefulLinks.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/tools',
    name:"工具",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "tools" */ '../views/tools/Tools.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/contacts',
    name:"资源",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "contacts" */ '../views/contacts/Contacts.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/contacts/view-contact/:id',
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "view-contact-id" */ '../views/contacts/ViewContact.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/snapshot',
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "snapshot" */ '../views/useful_links/Snapshot.vue'),
        meta:{requiresAuth:false}
      },
    ]
  },
  {
    path:'/market-study',
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "market-study" */ '../views/market_study/MarketStudy.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/proofreader',
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "proofreader" */ '../views/proofreader/Proofreader.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/wxmanage',
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "wxmanage" */ '../views/wxmanage/WxManage.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/opening',
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "opening" */ '../views/opening/Opening.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },
  {
    path:'/cms',
    component:Empty,
    children:[
      {
        path:'articles',
        component:Empty,
        // component: () => import('../views/cms/Articles.vue'),
        meta:{requiresAuth:true}
      },
      {
        name:"EditArticle",
        path:'edit-article/:id',
        component:Empty,
        // component: () => import('../views/cms/EditArticle.vue'),
        props:true,
        meta:{requiresAuth:true}
      },
      {
        path:'create-article',
        component:Empty,
        // component: () => import('../views/cms/EditArticle.vue'),
        meta:{requiresAuth:true}
      },
    ]
  },

]

const router = createRouter({
  // mode:'history' (https://medium.com/@ingrid.oberbuchlerova/vue-3-migration-9ec715fbe53b)
  history: createWebHistory(),
  routes,
  // @ts-ignore
  scrollBehavior: (to, from, savedPosition) => {
    // return desired position
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

import {routerGuard} from "./routerGuard"

router.beforeEach(routerGuard)

export default router
