import axios from 'axios'
import {backEndURL} from '@/config'
import { ref } from 'vue'


export const hotelData = ref([])
export const getHotelData = () => {
    return new Promise<any>((resolve, reject) => {
        if(hotelData.value.length > 0) {
            makeSearchCandidates(hotelData.value)
            resolve(hotelData.value)
            return 
        }else{
            axios.get(`${backEndURL}/hotels`)
            .then(res=>{
                if(res.data.status === 200){
                    
                    hotelData.value = res.data.payload

                    console.log('hotelData', hotelData.value)

                    makeSearchCandidates(hotelData.value)
                    
                    resolve(hotelData.value)
                }else{
                    console.log(res.data.message)
                }
            }).catch(err=>{
                console.log('getHotelData ERROR', err)
                reject(err)
            })
        }

    })
}
try{
    const checker = setInterval(()=>{
        if(hotelData.value.length > 0){
            clearInterval(checker)
        }else{
            getHotelData()
        }
    },2000)
}catch(err){}


export const districtMap = ref({} as any)

export const searchCandidates = ref({
    city:[],
    brand:[],
    management_group:[],
    ownership:[],
} as any)

const makeSearchCandidates = (hotels:any[]) =>{
    for(let k of Object.keys(searchCandidates.value)){
        for(let h of hotels){
            districtMap.value[h.district] = h.city
        }
        const data = hotels.map(h=>h[k])
                        .reduce((a,b)=> (b == '') ? a : a.includes(b) ? a : [...a,b], [])
        searchCandidates.value[k] = data
    }
}
