import OSS from "ali-oss"
import { getOSSKey } from '.';

export const initOSSClient = () => {
  return new Promise<any>((resolve, reject)=>{
    
    getOSSKey()
    .then(ossConfig=>{
      const client = new OSS({
        ...ossConfig,
        bucket:'hm-internal'
      });
      resolve(client);
    }).catch(err => {
      console.log(err)
      reject();
    })

  })
}
