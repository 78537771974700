import * as dd from 'dingtalk-jsapi'; 
import {corpId} from '@/config'
 
interface ddInfo {
  code: string;
}

export const ddLogin = () =>{
    return new Promise<ddInfo>((resolve, reject) =>{
        dd.ready(()=>{
        dd.runtime.permission.requestAuthCode({
            corpId,
            // @ts-ignore
            onSuccess: function (info:ddInfo) {
                console.log('ddLogin', info)
                resolve(info)
            },
            onError: function (error){
                alert('ddLogin ERROR' + error)
            }
        }).then(res =>{
            // reject(res)
        }).catch(err =>{
            reject(err)
        })
        })
    })
}