const imgReg = /<img.*?">/g

export function htmlToText(s:string) {
  const span = document.createElement('span');
  // addImgSize(s)
  span.innerHTML = s.replace(new RegExp("<br/>",'gm'), "&nbsp&nbsp")
                    .replace(imgReg, '[图片]');
  return span.textContent || span.innerText;
};


// const addImgSize = (s:string) => {
//   const imgTags = imgReg.exec(s)
// } 