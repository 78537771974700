import {getDDTicket} from './getDDTicket'
import store from '@/store'
import * as dd from 'dingtalk-jsapi'

export const configDD = (jsApiList:string[]) => {
    return new Promise((resolve, reject)=>{
        try{
            getDDTicket().then((config)=>{
                store.commit('hideLoading')
                dd.config({
                    ...config, jsApiList
                })

                // 查看鉴权错误信息
                dd.error(function(err) {
                    // modal.alert('加载预览失败，请刷新页面！')
                    // console.log('dd ticket err', err)
                });
                }).catch(err=>{
                // modal.alert('预览失败，请刷新页面！')
                store.commit('hideLoading')
            })
        }catch(err){
            console.log(err)
        }
    })
}