import axios from "axios";
import {initOSS, getCookie} from "@/utils/";
import {backEndURL} from "@/config";
import { updateCoins } from "@/utils/";

const promptLogin = () =>{
  const target = window.location.href
  const loginPage = `https://oapi.dingtalk.com/connect/qrconnect?appid=dingrwa1tqrwd6nadgir&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=`
    + encodeURIComponent(target);
  window.location.href = loginPage;
}


export const validateJWT = () => {
  
  const token = getCookie('jwt')

  return new Promise((resolve, reject)=>{
    if(!token){
      promptLogin()
    }else{
      axios.get(`${backEndURL}/test-jwt`,  {withCredentials: true})
      .then(res=>{
        if(res.data.status == 200){
          initOSS()
          updateCoins()
          resolve(1)
        }else{
          promptLogin()
          reject('failed jwt check 01')
        }
      }).catch(()=>{
        promptLogin()
        reject('failed jwt check 02')
      })
    }
  })
  

}
