import { binaryRead } from './binaryRead';
import PizZip from 'pizzip';
import docxtemplater from 'docxtemplater';

export const generateDoc = (tFile:File) =>{
  return new Promise<docxtemplater>((resolve, reject)=>{
    
    if(!tFile.name){ alert( "请先上传文件！")}

    binaryRead(tFile)
    .then(binary =>{
      const zip = new PizZip(binary);
      console.log("zip", zip)
      try{
        const doc:docxtemplater = new docxtemplater(zip, 
          { 
            paragraphLoop: true, 
            linebreaks: true, 
            delimiters: { start:'{{', end:'}}' }
          })
        resolve(doc as docxtemplater)
      }catch(err){
        alert("error making file")
        reject(err)
      }
    })
  })
}