const headerHeight = '50px'

const corpId = 'ding9c9a7a645dc88ccb35c2f4657eb6378f'



const LOCAL_BACKEND = false

const backEndURL = process.env.NODE_ENV === 'production' ? 
                    'https://backend.huamei2001.com'
                    : LOCAL_BACKEND ? 'http://localhost:4004'
                    : 'https://backend.huamei2001.com'


const dataVisURL = "https://aic.huamei2001.com"


export { 
    backEndURL,
    dataVisURL,
    headerHeight,
    corpId,
}

