
import { useRouter } from 'vue-router';
import store from '@/store'
import TButton from "@/components/TButton.vue";
import Expander from "@/components/ux/Expander.vue";
import { defineComponent, ref, computed, watch } from 'vue'

import {authStore} from '@/utils/auth/authStore'
import {version} from '@/version'

import "ant-design-vue/dist/antd.css"
import { DownOutlined } from '@ant-design/icons-vue';

import coinsAdded from '@/store/coinsAdded'

export default defineComponent({
  name: "Nav",
  components:{
    TButton,Expander,
    DownOutlined
  },
  setup(){

    // toggle templator
    const showTemplator = ref(false);
    const toggleTemplator = () =>{
      showTemplator.value = !showTemplator.value;
    }

    // log out
    const logOut = () => {
      store.commit("logOut")
      document.cookie = "jwt=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
      window.location.href = "https://huamei2001.com"
    }

    // routing
    const router = useRouter()
    const routerMapping = (url:string) => {
      if(url.includes("/useful-links")){
        return "知识地图"
      }else if(url.includes("/projects")){
        return "项目故事"
      }else if(url.includes("/wenda")){
        return "流程&问答"
      }else if(url.includes("/data-vis")){
        return "华美数据"
      }else if(url.includes("/opening")){
        return "开采数据"
      }else if(url.includes("/postdupublic")){
        return "官网文章"
      }else{
        return "华美工作台"
      }
    }

    const switchTo = (tab:string):string => {
      toggleMenu()
        router.push({
          path:tab,
        })
      return tab
    }

    // toggle menu display (mobile)
    const showMenu = ref(false)
    const toggleMenu = () => {
      showMenu.value = !showMenu.value
    }
    const scrolledDown = ref(false)
    const checkScrolledDown = (t:Event)=>{
        scrolledDown.value = document.documentElement.scrollTop>66
    }
    window.addEventListener('scroll',checkScrolledDown)

    // get curr dept
    const dept_id_list = computed(()=>store.state.userInfo.dept_id_list)

    // add coins
    const coin_enter = ref(false)
    watch(()=>store.state.addCoins, ()=>{
      if(store.state.addCoins==0){
        return
      }
      coin_enter.value=true
      setTimeout(() => {
        coin_enter.value=false
        store.commit('resetAddCoins')
      }, 1000);
    })

    return {
        
        authStore,
      toggleTemplator,
      logOut,
      switchTo,
      router,
      routerMapping,
      store,dept_id_list,

      showMenu, toggleMenu,scrolledDown,

      coin_enter,
      coinsAdded,

      version,
    }
  },
})
