import store from "@/store"
import{RouteLocationNormalized, NavigationGuardNext} from 'vue-router'

import refreshCoin from '@/components/TreasureHunt/refreshCoin'
import {recordVisit} from '@/utils/recordVisit'


export const routerGuard = (to:RouteLocationNormalized, _from:RouteLocationNormalized, next:NavigationGuardNext)=>{
    refreshCoin()
    // auth
  const requiresAuth = to.matched.some((record)=>record.meta.requiresAuth)

  const {fullPath, query, params} = to
  recordVisit({fullPath, query, params})

  if(false && requiresAuth && store.state.loginState != true){
    let counter = 0
    const checkLogin = setInterval(() => {
      if(store.state.loginState==true){
        clearInterval(checkLogin)
        next()
      }else{
        counter += 1;
        const urlSearchParams = new URLSearchParams(window.location.search)
        const { code } = Object.fromEntries(urlSearchParams.entries());
        if((!code)||counter > 300){
          console.log("failed guard")
          clearInterval(checkLogin)
          const loginPage = "https://oapi.dingtalk.com/connect/qrconnect?appid=dingrwa1tqrwd6nadgir&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=" + encodeURIComponent("https://staff.huamei2001.com");
          window.location.href = loginPage;
        }
      }
    }, 10);
  }else{
    store.commit("switchTab", to.fullPath)
    if(to.fullPath=='/postdupublic'){
        console.log('to', to.fullPath)
    }
    next()
  }
}