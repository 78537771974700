import {twoDigits} from './twoDigits'

export const timeDisplay = (str:string|number) =>{
  const date = new Date(Number(str));
  return date.getFullYear() + "-" 
        + twoDigits(1 + date.getMonth()) + "-" 
        + twoDigits(date.getDate()) 
        + " " + twoDigits(date.getHours()) + ":" 
        + twoDigits(date.getMinutes()) + ":" 
        + twoDigits(date.getSeconds());
}