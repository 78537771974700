import axios from "axios";
import store from '@/store'
import {initOSS} from "@/utils/";
import {backEndURL} from "@/config";
import { updateCoins } from "@/utils/";

export const webLogin = (code:string) => {
  return new Promise<number>((resolve, reject) => {
    axios.get(`${backEndURL}/auth/weblogin?code=${code}`,  {withCredentials: true})
    .then(res=>{
      console.log("loginResult",res)
      if(res.data.status === 200){
        const {userInfo, jwt} = res.data.payload;
        document.cookie = `jwt=${jwt};domain=huamei2001.com;`
        store.commit("logIn", {userInfo, jwt})
        initOSS()
        updateCoins()
        resolve(1)
      }else{
        if(process.env.NODE_ENV === 'development'){
          console.log('failed web login', res)
        }
        reject('failed web login')
      }
    })
    .catch(err=>{
    if(process.env.NODE_ENV === 'development'){
        console.log('failed to login:', err)
      }
      reject(err)
    })
  })
}